import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import firebase from "firebase";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "*",
    name: "Status",
    component: () => import("@/components/Status.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "/ipcalendar",
    name: "IpCalendar",
    component: () => import("@/components/IpCalendar.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "/ebstats",
    name: "EbStats",
    component: () => import("@/components/EbStats.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/components/Login.vue"),
    meta: {
      guest: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        next();
      } else {
        next({
          path: "/login"
        });
      }
    });
  } else if (to.matched.some(record => record.meta.guest)) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        next({
          path: "/"
        });
      } else {
        next();
      }
    });
  } else {
    next();
  }
});

export default router;
