import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import firebase from "firebase/app";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

const firebaseConfig = {
  apiKey: "AIzaSyBhd59S5eJZmWB0ASAeYaGcpV-InPxkCeY",
  authDomain: "ssrahul96-home.firebaseapp.com",
  databaseURL: "https://ssrahul96-home.firebaseio.com",
  projectId: "ssrahul96-home",
  storageBucket: "ssrahul96-home.appspot.com",
  messagingSenderId: "380630009372",
  appId: "1:380630009372:web:5c2c15d8a3c625d23b41d9"
};

firebase.initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
