<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand @click="navigateToHome">Status App</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-navbar-nav v-if="user" class="ml-auto">
        <b-collapse id="nav-collapse" is-nav>
          <b-nav-item @click="navigateToIpCalendar">IpCalendar</b-nav-item>
          <b-nav-item @click="navigateToEbStats">EbStats</b-nav-item>
          <b-nav-item-dropdown right>
            <template #button-content>
              <img
                :src="user.photoURL"
                style="width: 30px; height: 30px; border-radius: 50%"
              />
            </template>
            <b-dropdown-item @click="signoutButtonPressed"
              >Sign Out</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-collapse>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import router from "./../router/index.ts";

export default {
  data() {
    return {
      user: null
    };
  },
  created() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.user = user;
      }
    });
  },
  methods: {
    signoutButtonPressed(e) {
      e.stopPropagation();
      firebase.auth().signOut();
      router.go();
    },
    navigateToIpCalendar() {
      router.push("ipcalendar");
    },
    navigateToEbStats() {
      router.push("ebstats");
    },
    navigateToHome() {
      router.push("/");
    }
  }
};
</script>
